import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import DropHeader from '../shared/DropHeader'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.mobile`
    margin-top: 20px;
  `}
  ${media.tablet`
    margin-top: 40px;
  `}
  ${media.desktop`
    margin-top: 0px;
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  ${media.mobile`
    margin: 0 0 40px 0;
    height: 275px;
  `}
  ${media.tablet`
    height: 400px;
  `}
  ${media.desktop`
    margin: 0 0 35px 0;
    height: 650px;
  `}
`

const query = graphql`
  query {
    hero: file(relativePath: { regex: "/accessibility/hero.jpeg/" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, quality: 100)
      }
    }
  }
`

const Header = () => {
  const images = useStaticQuery(query)

  return (
    <Section>
      <DropHeader
        title={`Our Commitment\n to Accessibility`}
        isPageHeader
        mobileFontSize={50}
        tabletFontSize={64}
        fontSize={128}
      />
      <ImageWrapper>
        <GatsbyImage
          image={getImage(images.hero)}
          alt="Man feeding Finn supplement to sitting dog"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{
            objectFit: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        />
      </ImageWrapper>
    </Section>
  )
}

export default Header
