import React from 'react'
import styled from 'styled-components'

const Section = styled.section``

import Header from './Header'
import Body from './Body'

export default React.memo(() => (
  <Section>
    <Header />
    <Body />
  </Section>
))
