import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Accessibility from 'src/components/Accessibility'
import SEO from 'src/components/shared/SEO'

const SEO_CONFIG = {
  title: 'Accessibility | Finn',
  description: 'Our Commitment to Accessibility'
}

const AccessibilityPage: React.FC<PageProps> = ({ location }) => {
  const { title, description } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} location={location} />

      <Layout location={location}>
        <Accessibility />
      </Layout>
    </>
  )
}

export default AccessibilityPage
