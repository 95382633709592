import React from 'react'
import styled from 'styled-components'

import { Title, LargeP } from '../shared/text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.div`
  background-color: ${colors.white};
  color: ${colors.navy};
  width: 100%;
  ${media.mobile`
    padding: 0 10px 70px 10px;
  `}
  ${media.tablet`
    padding: 0 40px 100px 40px;
  `}
`

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  ${media.mobile`
    padding: 0 10px;
  `}
  ${media.tablet`
    padding: 0 40px;
  `}
  ${media.desktop`
    max-width: 1205px;
  `}
`

const StyledH6 = styled(Title)`
  margin: 40px 0;
`

const StyledLink = styled.a`
  color: ${colors.navy};
  margin-left: 4px;

  &:hover {
    color: ${colors.orange};
  }
`

const Body = () => (
  <Section>
    <Container>
      <LargeP>
        Finn is committed to providing a website that is accessible and navigable to all users. We
        actively work to assess and increase the accessibility and usability of our website, and
        follow the guidelines of the Web Accessibility Initiative. The Web Accessibility Initiative
        guidelines help us understand how we can make our web content more accessible to individuals
        with disabilities.
      </LargeP>
      <StyledH6>Our Ongoing Efforts</StyledH6>
      <LargeP>
        We regularly review our site in comparison to these guidelines using recognized
        accessibility evaluation tools. While we strive to adhere to the accepted guidelines and
        standards for accessibility and usability, it is not always possible to do so in all areas
        of the website at all times. If you are having difficulty viewing or navigating the content
        on this website, or notice any content, features, or functionality that you believe is not
        fully accessible to people with disabilities, please let us know. If you have any
        suggestions as to how we can further improve the accessibility, please let us know.
      </LargeP>
      <StyledH6>Feedback</StyledH6>
      <LargeP>
        We welcome your feedback. We take all feedback seriously and will consider it as we evaluate
        ways to accommodate all of our customers and our overall accessibility policies. We have
        several ways that you can share your feedback and overall experience with the accessibility
        of our website: <br />
        <br />
        Phone: <StyledLink href="tel:+12013663840">(201) 366-3840</StyledLink>
        <br /> Email: <StyledLink href="mailto:support@petfinn.com">support@petfinn.com</StyledLink>
        <br />
        Social:{' '}
        <StyledLink
          href="https://www.instagram.com/petfinn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          @petfinn
        </StyledLink>
      </LargeP>
    </Container>
  </Section>
)

export default Body
